// components/NotLoggedInPage.tsx

import React from 'react'
import Layout from './Layout'
import Container from '../components/Container'
import Link from 'next/link'

const GuestHomePage: React.FC = () => {
  return (
    <Layout>
      <section className="home-hero bg-gradient-to-r bg-[url('/assets/images/Home-hero.png')] from-blue-500 to-blue-700 bg-cover bg-center bg-no-repeat py-14 text-white" style={{ height: '530px', backgroundSize: '100% 100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Container className="my-0 xl:my-24 md:my-0 sm:my-0">
          <h1 className="md:font-serif lg:font-sans pb-1 xl:px-28 px-16 mb-5 md:mb-14 text-center font-bold xl:text-6xl xl:leading-normal lg:text-4xl md:text-3xl sm:text-2xl text-2xl leading-normal">
            Exclusive, real-time Power, Energy & Renewables deal flow
          </h1>
          <div className="text-center">
            <Link href="/login">
              <a className="rounded-lg border border-gray-300 bg-white px-6 py-3.5 font-semibold text-black hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700 inline-block text-[15px]">
                Get Access
              </a>
            </Link>
          </div>
        </Container>
      </section>

      <section>
        <Container className="py-10 xl:py-30 lg:py-32 md:py-10 sm:py-10">
          <div className="grid grid grid-cols-1 gap-10 lg:grid-cols-2 font-inter">
            <div className="">
              <div>
                <h1 className="mb-5 xl:text-xl lg:text-xl font-bold font-inter">
                  1. Proprietary deal flow
                </h1>
                <p className="pb-[15px] text-[15px] font-light font-inter">
                  Stay ahead of the market with exclusive intelligence on
                  asset-level development & financing. Plus:
                </p>
                <p className="text-[15px] font-light font-inter">
                  PeakLoad’s proprietary database of live M&A deals.
                </p>
              </div>
              <hr className="mt-5 mb-5 border-dashed border-black"></hr>
              <div>
                <h1 className="mb-5 xl:text-xl lg:text-xl font-bold">2. Corporate news</h1>
                <p className="text-[15px] font-ligh font-inter">
                  PeakLoad goes beyond press releases to provide exclusive
                  intelligence on corporates in the sector.
                </p>
              </div>
              <hr className="mt-5 mb-5 border-dashed border-black"></hr>
              <div>
                <h1 className="mb-5 xl:text-xl lg:text-xl font-bold">3. Personnel moves</h1>
                <p className="text-[15px] font-light font-inter">
                  Keep track of key deal makers and learn about moves before
                  they hit LinkedIn.
                </p>
              </div>
              <hr className="mt-5 mb-5 border-dashed border-black"></hr>
              <div>
                <h1 className="mb-5 xl:text-xl lg:text-xl font-bold">4. Energy trading</h1>
                <p className="text-[15px] font-light font-inter">
                  Find out which counterparties are winning hedging contacts,
                  and read about deal terms.
                </p>
              </div>
            </div>
            <div className="">
              <div>
                <img
                  className="h-auto w-full"
                  src="/assets/images/Deal-Database.png"
                  alt="image description"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="bg-gray-50" id='about-us'>
        <Container className="py-10 xl:py-30 lg:py-32 md:py-10 sm:py-10">
          <h1 className="mb-20 text-center text-3xl font-bold font-inter">About Us</h1>
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
            <div className="border-t border-dashed border-black font-inter">
              <div className="mb-5 block items-center sm:flex md:flex lg:flex xl:flex">
                <span className="bg-black p-1 px-3 text-[15px] text-white nm_about" style={{ marginTop: '-1px' }}>
                  Victor Kremer
                </span>
                <p className="px-3 py-3 text-[15px] font-semibold sm:px-3 sm:py-0 md:px-3 lg:px-3 xl:px-3 ">
                  Co-founder & Editor
                </p>
              </div>
              <p className="text-sm-[100px] mb-8 text-[15px] font-light">
                During a 24-year career covering the power, energy and
                renewables sectors, Victor previously co-founded SparkSpread in
                2005, and served as the publication’s co-editor between 2005 and
                2021.
              </p>
              <p className="text-[15px] font-light">
                Before that, he spent nine years at Institutional Investor’s
                newsletter division, where he launched Power, Finance & Risk in
                New York, and latterly served as executive editor and London
                bureau chief.
              </p>
            </div>
            <div className="border-t border-dashed border-black font-inter">
              <div className="mb-5 block items-center sm:flex md:flex lg:flex xl:flex">
                <span className="bg-black p-1 px-3 text-[15px] text-white nm_about" style={{ marginTop: '-1px' }}>
                  Vlad Roytman
                </span>
                <p className="px-3 py-3 text-[15px] font-semibold sm:px-3 sm:py-0 md:px-3 lg:px-3 xl:px-3">
                  Co-founder & Commercial Director
                </p>
              </div>
              <p className="mb-8 text-[15px] font-light">
                Vlad was most recently an account executive at Celonis, where he
                covered the utilities industries.
              </p>
              <p className="text-[15px] font-light">
                Previously, Vlad was deputy head of sales for the Americas at
                Inframation, where his responsibilities included building out
                the U.S. commercial business of SparkSpread.
              </p>
            </div>
            <div className="border-t border-dashed border-black font-inter">
              <div className="mb-5 block items-center sm:flex md:flex lg:flex xl:flex">
                <span className="bg-black p-1 px-3 text-[15px] text-white nm_about" style={{ marginTop: '-1px' }}>
                  Will Ainger
                </span>
                <p className="px-3 py-3 text-[15px] font-semibold sm:px-3 sm:py-0 md:px-3 lg:px-3 xl:px-3">Co-Editor</p>
              </div>
              <p className="mb-8 text-[15px] font-light">
                Will has reported on the EMEA energy, power & renewables markets
                since 2001. He co-founded SparkSpread in 2005 and was co-editor
                between 2005 and 2021.
              </p>
              <p className="text-[15px] font-light">
                Before that, he spent eight years at Institutional Investor’s
                newsletter division in New York and London, where, among other
                roles, he was managing editor of Power, Finance & Risk.{' '}
              </p>
            </div>
            <div className="border-t border-dashed border-black font-inter">
              <div className="mb-5 block items-center sm:flex md:flex lg:flex xl:flex">
                <span className="bg-black p-1 px-3 text-[15px] text-white nm_about" style={{ marginTop: '-1px' }}>
                  Tom Goulding
                </span>
                <p className="px-3 py-3 text-[15px] font-semibold sm:px-3 sm:py-0 md:px-3 lg:px-3 xl:px-3">
                  Deputy Editor, EMEA
                </p>
              </div>
              <p className="text-[15px] font-light">
                He was most recently deputy editor of reNEWS and before that was
                a journalist covering renewable energy project finance and M&A
                at Environment Media Group, Inframation and SparkSpread.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default GuestHomePage
